.EmailSetting{

    .EmailTิable {


    .Tool {
        width: 50px !important;  
      }

      td.diff {
      input.Email{
        color: #b37300 ;

      }
    }
}
    


}