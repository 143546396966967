
.head_detail {
    font-size: 1.05rem;
    .dataLable{
        color:rgb(140, 140, 140)
       
    }
 
}





