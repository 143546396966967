.BillScopeSetting {

  .BillScopeTable.Non-Seperate {

    .Tool {
      width: 50px;

    }

  }

  .BillScopeTable.Seperate {    
    .Tool {
        width: 60px;
     // width: 50px;    
    }
  }



  .BillScopeTable {
    width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
  
      thead {
        background-color: #e6e6e6;
        th {
          vertical-align: text-top;
          border-bottom: 2px solid rgba(222, 226, 230, 0.7);
        }
  
      
      }
  
      tbody {
        background-color: #fefefe;
  
        td {
          vertical-align: baseline;
          border-top: 1px solid rgba(222, 226, 230, 0.5);
          white-space: pre-wrap;
        }
  
        td.diff {
          //color: #b37300 !important;
          // .d3_pickerselecteditem{
          //   color: #fff6e6 !important;
          // }
          textarea{
            color: #b37300 ;

          }
          .d3_pickerselecteditem{
            background-color: #ad660a;

          }
        }
        
  
  
  
        .displayRow.just_new_row {
          background-color: #f2f2f2;
        }
      }
  
      th,    td {
        padding: 0.2rem;
        //  vertical-align: baseline;
      }
  
      .Tool {   
        text-align: right;
        padding-right: 10px;
  
        .disabled {
          opacity: 0.5;
        }
      }
  
      .No{

        width: 35px;
        text-align: right;
        padding-right: 10px;
      }
      
       .BillScopeName{
       width: 250px;
      }

     .ScopeBy {
      //  width: 250px;
       min-width: 250px;
      }


      .ERPCustomer {
        width: 270px;
      }
      .Email {
        width: 550px;
      }


      .ViewUser {
        width: 450px;
      }
      .CreateUser {
        width: 100px;
        padding-left: 5px  !important;
        padding-right: 10px !important;;
      }

      .btnUser {
        width: 120px;
      }





  

  
   
    }
  }