.report01{
    .text-highlight{
        color:rgb(26, 164, 199);
    }

    .BillReportTable-button{
        padding: 9px;
        font-size: 15px;
        background: none;
        border-radius: 6px;
        border: solid 1px green;
        color: green;
    }

    .BillReportTable-button:hover{
        color: whitesmoke;
        background-color: green;
        cursor: pointer;
        transition: background-color 0.25s ease-out;
    }

    .BillReportTable-button__postion{
        @media screen and (max-width: 1200px) {
            justify-content: flex-start !important;
            padding-top: 10px;
        }
    }
}

.total__container { 
    padding: 20px;
    background: #e8e9ec;


}

.csp__container {

    
   // background: red;
}


