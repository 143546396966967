





 a.InfoTip:hover {

text-decoration: none !important;
}

.InfoTip {
    cursor: pointer;
     display: inline-flex;
     align-items: center;
     justify-content: center;
    width: 19px;
    height: 19px;
    margin-left: 5px;
    border: 1px solid #BDE0FF;
    border-radius: 50%;
    padding: 5px;
    background-color: #F0F8FF;
    font-weight: bold;
    color: gray;
   // font-size: 0.9em  ;
    font-size: 12px  ;

}
.InfoTip.small {

    width: 15px;
    height: 15px;

}



// .InfoTip {
//     cursor: pointer;
//      display: inline-flex;
//      align-items: center;
//      justify-content: center;
//     width: 19px;
//     height: 19px;
//     margin-left: 5px;
//     border: 1px solid #BDE0FF;
//     border-radius: 50%;
//     padding: 5px;
//     background-color: #F0F8FF;
//     font-weight: bold;
//     color: gray;
//    // font-size: 0.9em  ;
//     font-size: 12px  ;

// }



.InfoTip.blue {
 
    background-color:  #FFE5B4;
    color: #e5f2ff;
    border: 1px solid #66b0ff;
   

}



.InfoTip.orange {
 
    background-color:  #e79700;
    color: #e5f2ff;
    border: 1px solid #ffcb67;
   

}


.InfoTip.lightblue {
 
    background-color:  #66b0ff;
    color: #e5f2ff;
    border: 1px solid #99caff;
   

}


.InfoTip.gray {
 
    background-color:  #606060;
    color: #F5F5F5;
    border: 1px solid #696969;
   
   

}

.InfoTip.pink {
    background-color:  #ff5a77    ;
    color: #ffdae0;
    border: 1px solid #ff748c;
   
   

}


.InfoTip.purple {
 
    background-color:  #606fc7;
    color: #e4e7f6;
    border: 1px solid #98a2db;
   

}












