.custom-file-label {
    justify-content:left !important;
}

.table-list thead{
     background-color: #d9d9d9;
}

.table-list th{
    text-align: center;
}

.custom-file-input{
    cursor: pointer;
}