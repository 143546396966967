.Customer{

  .attachTB{
    font-size: 0.8rem;

  
  }

  .table-100{

    width: 100%;
  }

  td{
    vertical-align: top;
    
      }


  .labelSymbolCol{

    width : 15px ;
  }



  .LabelCol{
    width : 150px  !important ;
   max-width : 150px  !important ;
    //padding-left : 15px ;
    //padding-right : 15px ;

  }



.decisionContainer{
.form-check-input {

  margin-top: 0.1rem;
}

}
  


  .row{
    margin-top: 0.1rem;
  margin-bottom: 0.1rem;

  }


  .custom-radio{

    .custom-control-label{

       font-weight: unset;
    }
  }





}