
.usermenupage { 
 
        

    tr.permission{

  color: #0056b3
        
    }

    tr.permission td{

        vertical-align:middle;
   padding: 0.5em;
              
          }



    

.btnSetting{
    text-align:center;

}

.hidden{

 visibility: hidden;
}



.checkCircle{
    display: inline;
    vertical-align:middle;
    // vertical-align:0px;
    font-size: 1.7em;
    color: #00b100;
}

.checkCondition{
  display: inline;
  vertical-align:middle;
  // vertical-align:0px;
  font-size: 1.7em;
  color: #0056b3
}






    .tablemenu{

        border: 1px solid rgba(0,0,0,0.1);

    //   th,  td {
    //         border: 1px solid rgba(0,0,0,0.2);
    //     }

    //     td:first-child, th:first-child {
    //         border-left: none;
    //    }













   
    }




    th{
        font-size: 1.2em;
 
    text-align:center
   }

     th, td {
    border: 1px solid rgba(0,0,0,0.09);
      }
       tr:first-child td {
        border-top: 0;
      }
       tr td:first-child {
        border-left: 0;
      }
       tr:last-child td {
        border-bottom: 0;
      }
       tr td:last-child {
        border-right: 0;
      }

      tr:first-child th {
        border-top: 0;
      }
       tr th:first-child {
        border-left: 0;
      }

      tr:last-child th {
        border-bottom: 0;
      }
       tr th:last-child {
        border-right: 0;
      }


      td {
       
        border-bottom: 0;
      }



}




