.linkText {

    background:none!important;
    color:#007bff;
    border:none; 
    padding:0!important;
    font: inherit;
    /*border is optional*/
    border-bottom:1px solid #007bff; 
    cursor: pointer;

}

.linkText:hover { 
    color:#0056b3;
  }


  .normalTextLabel { 
    font-weight: normal!important ;
  }




  .reactStrapLink {
      cursor: pointer;
    box-sizing: border-box;
    color: rgb(0, 123, 255);
    text-align: left;
    text-decoration: none solid rgb(0, 123, 255);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 123, 255);
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: rgb(0, 123, 255);
    border: 0px none rgb(0, 123, 255);
    font: normal normal 400 normal 16px / 24px Karla, Sarabun, sans-serif;
    margin: 0px 16px 0px 0px;
    outline: rgb(0, 123, 255) none 0px;
}/*#A_1*/

.reactStrapLink:after {
    box-sizing: border-box;
    color: rgb(0, 123, 255);
    text-align: left;
    text-decoration: none solid rgb(0, 123, 255);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 123, 255);
    caret-color: rgb(0, 123, 255);
    border: 0px none rgb(0, 123, 255);
    font: normal normal 400 normal 16px / 24px Karla, Sarabun, sans-serif;
    outline: rgb(0, 123, 255) none 0px;
}/*#A_1:after*/

.reactStrapLink:before {
    box-sizing: border-box;
    color: rgb(0, 123, 255);
    text-align: left;
    text-decoration: none solid rgb(0, 123, 255);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 123, 255);
    caret-color: rgb(0, 123, 255);
    border: 0px none rgb(0, 123, 255);
    font: normal normal 400 normal 16px / 24px Karla, Sarabun, sans-serif;
    outline: rgb(0, 123, 255) none 0px;
}/*#A_1:before*/

.reactStrapLink:hover {
    color: #0056b3;
    text-decoration: underline;
}



