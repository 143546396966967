
/*
     #      #  ####  #####    #####   ##   #####  #      ###### 
     #      # #        #        #    #  #  #    # #      #      
     #      #  ####    #        #   #    # #####  #      #####  
     #      #      #   #        #   ###### #    # #      #      
     #      # #    #   #        #   #    # #    # #      #      
     ###### #  ####    #        #   #    # #####  ###### ###### 
*/

.listTable {
    border: none;
    .rt-thead { 
        
        box-shadow:none !important;
        .rt-th { 
            background: transparent;
            border-right: none !important;
            // Text
            font-weight: bold;
            text-transform: uppercase;
            color: #b6bbbe;
        }
    }
    .rt-td { 
        background: transparent;
        border-right: none !important;
    }
    .rt-tbody {
        width: 30%;

        .rt-tr-group {
            border-bottom: none;
            background: white;
            border-radius: 4px;
            // margin-top: 4px;
            margin-bottom: 4px;
        }
    }
}

.menu_Head{
    margin-left: 2%;
    font-weight: bold;
}

.btn_Group{
    margin-left: 2%;
    border-color: black;
}


.hover-pointer:hover { 
    cursor: pointer;
}