.azure_analytic{ 

    .text-scroll{
        overflow: scroll;
        white-space: nowrap; 
        // text-overflow: ellipsis; 
    }

    .text-scroll::-webkit-scrollbar {
        display: none;
    }

    .serviceNameDDL{
        width: auto !important;
        font-weight: bold;
        font-size: 15px !important;
        height: auto !important;
        color: whitesmoke;
        border-color: #505061;
        background-color: #4e6779;
        transition:'width 5s in-out' !important;
    }

    // .serviceNameDDL-prepared{
    //     width: 0px !important;
    //     font-weight: bold;
    //     font-size: 15px !important;
    //     height: auto !important;
    //     color: whitesmoke;
    //     border-color: #505061;
    //     background-color: #4e6779;
    //     transition:'width 5s in-out' !important;
    // }

    a {
        text-decoration: none;
        opacity: .6;
        padding: 60px;
        font-weight: bolder;
        position: absolute;
        right: 0px;
        bottom: 0px;
        font-size: 1.4em;
    }
    
    a:hover {
        opacity: 1;
    }

    #serviceType-toggle {
        width: 195px;
        height: 29px;
        // margin: auto;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        user-select: none;
        cursor: pointer;
    }
    
    .inner-container {
        position: absolute;
        left: 0;
        top: 0;
        width: inherit;
        height: inherit;
        text-transform: uppercase;
        font-size: 0.6rem;
        letter-spacing: .1em;
    }
    
    .inner-container:first-child {
        background: #e9e9e9;
        color: #a9a9a9;
    }
    
    .inner-container:nth-child(2) {
        background: dodgerblue;
        color: white;
        -webkit-clip-path: inset(0 50% 0 0);
        // clip-path: inset(0 50% 0 0);
        transition: .3s cubic-bezier(0,0,0,1);
    }
    
    .ST-toggle {
        width: 50%;
        position: absolute;
        height: inherit;
        display: flex;
        box-sizing: border-box;
    }
    
    .ST-toggle p {
        margin: auto;
    }
    
    .ST-toggle:nth-child(1) {
        right: 0;
    }

        // New Toggle

        .onoffswitch {
            position: relative; width: 120px;
            -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
        }
        .onoffswitch-checkbox {
            display: none;
        }
        .onoffswitch-label {
            display: block; overflow: hidden; cursor: pointer;
            border: 2px solid #dcdcdc; border-radius: 6px;
        }
        .onoffswitch-inner {
            display: block; width: 200%; margin-left: -100%;
            transition: margin 0.3s ease-in 0s;
        }
        .onoffswitch-inner:before, .onoffswitch-inner:after {
            display: block; float: left; width: 50%; height: 29px; padding: 0; line-height: 30px;
            font-size: 12px; color: white; font-weight: bold;
            box-sizing: border-box;
        }
        .onoffswitch-inner:before {
            content: "Marketplace";
            padding-left: 9px;
            background-color: #B251ED; color: #F5F5F5;
        }
        .onoffswitch-inner:after {
            content: "Azure";
            padding-right: 26px;
            background-color: #28a745; color: #F5F5F5;
            text-align: right;
        }
        .onoffswitch-switch {
            display: block; 
            width: 31px; 
            height: 31px;
            margin: 1px;
            background: #dcdcdc;
            position: absolute; top: 0; bottom: 0;
            right: 87px;
            border: 2px solid #dcdcdc; border-radius: 6px;
            transition: all 0.3s ease-in 0s; 
        }
    
        .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
            margin-left: 0;
        }
    
        .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
            right: 0px; 
        }
    
        // New Toggle

    // .all-center{
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    
    //fadeInDown

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }
        
        to {
            opacity: 1;
        }
    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        
        to {
            opacity: 1;
        }
    }
    
    .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    @-webkit-keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    
    @keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
        
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    
    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }

    .toggleWapper{
        justify-content: flex-end;
    }


    @media (max-width: 767.98px) { 
        .toggleWapper{
            justify-content: center;
        }
    }
}