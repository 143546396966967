
.CustomSelectModalPanel {
    .input-group-prepend {
        button {
        height: calc(1.7rem + 2px);
        }
       
    }
   
}

