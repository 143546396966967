.dashboard{

    .fullDiv{
        display: flex;
        height: 100%;
        width: 100%; 
        align-items: center;
        justify-content: center;
        text-align: center;




    }


    .btnDownload{

        font-size: 1.6em;
        color:#003d6d;
        cursor: pointer;
        &:hover {
            color:#00006d
        }

    }


    .comcard{
        // margin-left: 15px;
        // margin-top: 10px;
        // margin-bottom: 10px;
        overflow: hidden;
        padding: 10px;
        width: 230px;
        height: 80px;
        border-bottom: solid 5px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);
        // transition: all .05s ease-in-out;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .comcard:hover{
        transform: scale(1.03);
        box-shadow: 0px 0px 0px rgba(30, 30, 30, 0.59);
    }

    // @keyframes cardlift {
    //     from {
    //         transform: scale(0);
    //     }
    //     to {
    //         transform: scale(1.3);
    //     }
    // }

    // @keyframes cardclick {
    //     from {
    //         transform: scale(1.3);
    //     }
    //     to {
    //         transform: scale(0);
    //     }
    // }

    .comcard:active{
        transform: scale(0.98);
        box-shadow: 0px 0px 2px rgba(66, 66, 66, 0.2);
    }

    .comcard-selected{
        transform: scale(1.03);
        box-shadow: 0px 0px 2px 3px  rgb(0, 61, 109);
        //#1ab29d
        // box-shadow: 1px 1px 5px rgba(30, 30, 30, 0.59);
    }

    .comcard-selected:hover{
        transform: scale(1.02);
        box-shadow: 0px 0px 5px rgb(0, 61, 109);
         //#1ab29d
        // box-shadow: 1px 1px 5px rgba(30, 30, 30, 0.59);
    }

    .comcard-space{
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .comcard-container {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .comcard-container:hover {
      //  overflow-y: scroll;
        overflow-y: auto;
    }

    .nav-tabs .nav-link {
        border: 0px solid transparent;
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem;
    }

    .nav-link {
        cursor: pointer;
        background-color: #e6e6e6;
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        border:0px;
        // padding-top: 10px;
        // padding-bottom: 10px;
        // box-shadow: 1px 4px 5px rgba(66, 66, 66, 0.2);
        box-shadow: 2px 0px 5px rgba(66, 66, 66, 0.2);
    }

    //Customer/Partner view toggle #2
    // .view_toggle1{
    //     border: solid 3px black;
    // }

    // .view_toggle2{
    //     border: solid 3px black;
    // }

    // .view_flaper{
    //     background-color: rgb(2, 2, 2);
    // }

    // .toggle {
    //     position: relative;
    //     border: solid 3px black;
    //     border-radius: 0px;
    //     transition: transform cubic-bezier(0, 0, 0.30, 2) .4s;
    //     transform-style: preserve-3d;
    //     perspective: 800px;
    // }
    
    // .toggle>input[type="radio"] {
    //     display: none;
    // }
    
    // .toggle>#choice1:checked~#flap {
    //     transform: rotateY(-180deg);
    // }
    
    // .toggle>#choice1:checked~#flap>.content {
    //     transform: rotateY(-180deg);
    // }
    
    // .toggle>#choice2:checked~#flap {
    //     transform: rotateY(0deg);
    // }
    
    // .toggle>label {
    //     display: inline-block;
    //     min-width: 170px;
    //     padding: 10px;
    //     margin-bottom: 0px;
    //     font-size: var(--font-size);
    //     text-align: center;
    //     color: var(--accent);
    //     cursor: pointer;
    // }
    
    // .toggle>label,
    // .toggle>#flap {
    //     font-weight: bold;
    //     text-transform: capitalize;
    // }
    
    // .toggle>#flap {
    //     position: absolute;
    //     // top: calc( 0px - var(--border-width));
    //     top: 10px;
    //     left: 50%;
    //     height: calc(100% + var(--border-width) * 2);
    //     width: 50%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: var(--font-size);
    //     background-color: var(--accent);
    //     border-top-right-radius: var(--border-radius);
    //     border-bottom-right-radius: var(--border-radius);
    //     transform-style: preserve-3d;
    //     transform-origin: left;
    //     transition: transform cubic-bezier(0.4, 0, 0.2, 1) .5s;
    // }
    
    // .toggle>#flap>.content {
    //     color: #333;
    //     transition: transform 0s linear .25s;
    //     transform-style: preserve-3d;
    // }

    //Customer/Partner view toggle #1
    .tgl {
        display: none;
        &,
        &:after,
        &:before,
        & *,
        & *:after,
        & *:before,
        & + .tgl-btn {
            box-sizing: border-box;
            &::selection {
                background: none;
            }
        }
        + .tgl-btn {
            outline: 0;
            display: block;
            width: 8.5em;
            height: 2em;
            position: relative;
            cursor: pointer;
        user-select: none;
            &:after,
        &:before {
                position: relative;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
            }
        
            &:after {
                left: 0;
            }
        
            &:before {
                display: none;
            }
        }
        &:checked + .tgl-btn:after {
            left: 50%;
        }
    }

    .tgl-flip {
        + .tgl-btn {
            padding: 2px;
            transition: all .3s ease;
            font-family: sans-serif;
            perspective: 100px;
            &:after,
        &:before {
                display: inline-block;
                transition: all .5s ease;
                width: 100%;
                text-align: center;
                position: absolute;
                line-height: 2em;
                font-weight: bold;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                backface-visibility: hidden;
                border-radius: 4px;
            }
            &:after {
                content: attr(data-tg-on);
                // background: #02C66F;
                background: #e52121;
                transform: rotateY(-180deg);
            }
            &:before {
                background: #1995ff;
                content: attr(data-tg-off);
            }
            &:active:before {
                transform: rotateY(-20deg);
            }
        }
        &:checked + .tgl-btn {
            &:before {
                transform: rotateY(180deg);
            }
            
            &:after {
                transform: rotateY(0);
                left: 0;
                background: #e52121;
            }
            
            &:active:after {
                transform: rotateY(20deg);
            }
        }
    }

//     /*flex-direction: row | row-reverse | column | column-reverse;*/

//     .flex-container.row {
//         -webkit-flex-direction: row;
//         -ms-flex-direction: row;
//         flex-direction: row;
//     }

//     .flex-container.row-reverse {
//         -webkit-flex-direction: row-reverse;
//         -ms-flex-direction: row-reverse;
//         flex-direction: row-reverse;
//     } 

//     .flex-container.column {
//         -webkit-flex-direction: column;
//         -ms-flex-direction: column;
//         flex-direction: column;
//     }

//     .flex-container.column-reverse {
//         -webkit-flex-direction: column;
//         -ms-flex-direction: column;
//         flex-direction: column-reverse;
//     }

// /*flex-wrap: nowrap | wrap | wrap-reverse;*/

//     .flex-container.nowrap {
//         -webkit-flex-wrap: nowrap;
//         -ms-flex-wrap: nowrap;
//         flex-wrap: nowrap;
//     }

//     .flex-container.wrap {
//         -webkit-flex-wrap: wrap;
//         -ms-flex-wrap: wrap;
//         flex-wrap: wrap;
//     }

//     .flex-container.wrap-reverse {
//         -webkit-flex-wrap: wrap-reverse;
//         -ms-flex-wrap: wrap-reverse;
//         flex-wrap: wrap-reverse;
//     } 

    @keyframes direction-row2column {
        from { flex-direction: row; }
        to { flex-direction: column; }
    }

    @keyframes direction-column2row {
        from { flex-direction: column; }
        to { flex-direction: row; }
    }


    //Animate Flexbox Direction
    .group {
        display: flex;
        flex-direction: row;
    }

    .group.reorder {  
        display: flex;
        flex-direction: column;
    }

    // .cardcom-group {
    //     display: flex;
    //     flex-direction: row;
    // }

    // .cardcom-group.reorder {  
    //     display: flex;
    //     flex-direction: column;
    // }

    //date picker modified
    // .datepicker-ios{
    //     top:-200px;
    //     left:-15px,
    // }

    .react-datepicker-popper{
        transform: translate3d(0px, 37px, 0px) !important;
    }

    .react-datepicker__month-wrapper{
        display: flex;
        width:140px;
    }

    .react-datepicker{
        font-size: 10px;
    }

    .react-datepicker__header {
        font-size: 12px;
    }

    // .react-datepicker-ignore-onclickoutside{

    // }


    .text-ellipsis{
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis; 
    }

    .comcard-label{

        height: 35px;
        font-size: 0.7rem;
        padding-bottom: 1rem; 

      //  font-size: 9px;
       //  white-space: nowrap;
         text-overflow: ellipsis;
        //overflow-clip-margin: hidden;
        overflow: hidden;
        // overflow: scroll;

        // style={{ height: '40px'
        //     , fontSize: '0.7rem', paddingBottom: '1.08rem' }}


    }

    .comcard-label-bottom {

        height: 20px;
        font-size: 0.7rem;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // overflow: scroll;
    }


    


    .comcard-label::-webkit-scrollbar {
        display: none;
    }

    .all-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .all-left{
        display: flex;

        margin-left:20px;
    }

    .sub-header{
        color: #808080;
        font-size: 1.5em;
        font-weight: bold;

    }

    //responsive
    .rightSider{
        display: flex;
        flex-direction: column;
        padding: 0px;
        height: 100%;

        background-color:#f1f3f6;
        transition: all 1.5s,
    }

    .leftSider{
     
        overflow-x: auto;    
        /*
            overflow-y: auto;
        */


        flex: 1;
        display: flex;
       // height: 100%;
        background-color: #f1f3f6;
        transition: all 1.5s;
    }

    .wrapperCompanyCard{
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
    }

    .wrapperContentCard{
        display: flex ;
        flex-direction: column;
        margin-right: 1rem;
        margin-left: 0.25rem;
    }

    .contentOverflow{
        // overflow: hidden scroll;
        overflow-x: hidden;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .contentOverflow:hover{
        overflow-y: scroll;
    }

    .fixScrollBar {
        overflow-y: scroll !important;
    }

    .customMobileBtn{
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        border: 0px;
        color: #a2a2a2;
        background-color: #f1f3f6;
        border-bottom-right-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
        transition: all 1.5s;
    }

    .customMobileBtn:focus{
        outline: 0px;
    }

    .hidescrollbar::-webkit-scrollbar {
        display: none;
    }

    .fullScreenLeftSider{
        height: 72.25vh;
    }

    .tab-content{
       // height: calc(100% - 50px);
    }

    // Medium devices (tablets, less than 992px)
    @media (max-width: 1080px) { 
        .rightSider{
            height: 175px;
        }

        .leftSider{
            background-color: #fff;
        }

        .comcard-container{
           // flex-wrap: nowrap;
        }

        .comcard-last{
            margin-right: .5rem;
        }

        .wrapperContentCard{
            margin-right: 0rem;
            margin-left: 0rem;
        }

        .wrapperCompanyCard{
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 767.98px) { 

        .rightSider{
            margin-left: -20px;
        }

        .leftSider{
            margin-left: -20px;
            margin-right: -20px;
        }

        .comcard-last{
            margin-right: .7rem;
        }

        .tab-content{
            margin-bottom: 0px !important;
        }

        // .contentOverflow{
        //     overflow: scroll;
        // }
    }

    @media only screen and (max-width: 807px) and (min-width: 768px)  {
        .selectViewer{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }

    @media only screen and (max-width: 681px) and (min-width: 408px)  {
        .selectViewer{
            margin-top: 0.5rem;
            // margin-bottom: 0.5rem;
        }
    }

}