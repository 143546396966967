$primary-color: #0072ff;
$primary-gradient: linear-gradient(to right, #0072ff, #00adff);

$bluesky : #00b9f9;
$violet : #9400ff;
$light-blue : #c8e5ff;
$light-bluesky : #c5f0ff;
$light-violet : #ebd5fb;


// Badge color
.badge-light-blue {
  background: $light-blue;
  color: $primary-color;
}
.badge-light-bluesky {
  background: $light-bluesky;
  color: $bluesky;
}
.badge-light-violet {
  background: $light-violet;
  color: $violet;
}


.min-w-150 { 
    min-width: 150px;
}

/* 
  #       ####    ##   #####  # #    #  ####  
  #      #    #  #  #  #    # # ##   # #    # 
  #      #    # #    # #    # # # #  # #      
  #      #    # ###### #    # # #  # # #  ### 
  #      #    # #    # #    # # #   ## #    # 
  ######  ####  #    # #####  # #    #  ####  
*/

.loading {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #16171d;
    opacity: 0.9;
    position: fixed;
    z-index: 1001;
  }
  
  .loading > div {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    top: 50%;
    margin-top: -30px;
  }
  
  
  .loading > div > div {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #0072ff;
    top: 10px;
    left: 10px;
    transform-origin: 20px 20px;
    border-radius: 8px;
    animation: spin-a 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
  }
  
  .loading > div > .c2 {
    top: 10px;
    left: auto;
    right: 10px;
    transform-origin: -4px 20px;
    animation: spin-b 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
  }
  .loading > div > .c3 {
    top: auto;
    left: auto;
    right: 10px;
    bottom: 10px;
    transform-origin: -4px -4px;
    animation: spin-c 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
  }
  .loading > div > .c4 {
    top: auto;
    bottom: 10px;
    transform-origin: 20px -4px;
    animation: spin-d 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
  }
  
  @keyframes spin-a {
    0%   { transform: rotate(90deg); }
    0%  { transform: rotate(90deg); }
    50%  { transform: rotate(180deg); }
    75%  { transform: rotate(270deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes spin-b {
    0%   { transform: rotate(90deg); }
    25%  { transform: rotate(90deg); }
    25%  { transform: rotate(180deg); }
    75%  { transform: rotate(270deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes spin-c {
    0%   { transform: rotate(90deg); }
    25%  { transform: rotate(90deg); }
    50%  { transform: rotate(180deg); }
    50%  { transform: rotate(270deg); }
    100% { transform: rotate(360deg); }
  }
  @keyframes spin-d {
    0%   { transform: rotate(90deg); }
    25%  { transform: rotate(90deg); }
    50%  { transform: rotate(180deg); }
    75%  { transform: rotate(270deg); }
    75% { transform: rotate(360deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  .loading > span {
    width: 100px;
    height: 30px;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: 30px;
    color: #0072ff;
    font-size: 12px;
    text-align: center;
  }


  .miniloading{
    bottom: 40%;
    right: 35%;
    width: 20%;
    height: 10%;
    background: #16171d;
    opacity: 0.5;
    position: absolute;
    z-index: 1001;
  }

  .miniloading > div {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    top: 50%;
    margin-top: -30px;
  }
  .miniloading > span {
    width: 100px;
    height: 30px;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    color: #0072ff;
    font-size: 22px;
    text-align: center;
  }
  .iconmini{
    margin-left: 20px;
    color: #0072ff;
    font-size: 24px;
    align-items:  center;
  }

  .inputCompany{
    background-color: #0072ff !important; 
    color: white !important;
  }
  .colorBlue{
    color: #0072ff;
  }

  .table__loading { 
      background: #fff;
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
  }
/*                                             
  #####    ##    ####  # #    #   ##   ##### ###### 
  #    #  #  #  #    # # ##   #  #  #    #   #      
  #    # #    # #      # # #  # #    #   #   #####  
  #####  ###### #  ### # #  # # ######   #   #      
  #      #    # #    # # #   ## #    #   #   #      
  #      #    #  ####  # #    # #    #   #   ###### 
*/

  
  .Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
    
  }
  
  .Table__pagination {
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    padding: 20px 10px;
  }
  
  .Table__pageButton {
    font-weight: bold;
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #2081f7;
    background: #c8e5ff;
    border-radius: 5px;
    margin-left: 4px;
    margin-right: 4px;
  }
  // Prev Button
  .Table__prevPageWrapper .Table__pageButton { 
    background: transparent;
  }
  // Next Button
  .Table__nextPageWrapper .Table__pageButton { 
    background: transparent;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: #6eabfe;
  }
  
  .Table__pageButton--active {
    color: #fff;
    background: #0072ff;
    border-radius: 4px;
    font-weight: bold;
  }
  
                                  
  // #####   ##   #####  #      ###### 
  //   #    #  #  #    # #      #      
  //   #   #    # #####  #      #####  
  //   #   ###### #    # #      #      
  //   #   #    # #    # #      #      
  //   #   #    # #####  ###### ###### 

.clickable-tr { 
  .ReactTable { 
    .rt-tr-group { 
      cursor: pointer;
      &:hover { 
        background: #f7f7f7;  
      }
    }
  }
}