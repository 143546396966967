@charset "UTF-8";

html{
    background-color: white;
}

body {
    font-family: "Karla" ,"Sarabun", sans-serif !important;
    /* background: #f6f7fb; */
    background-color: white;
    color: #003661;
    -webkit-font-smoothing: antialiased;

    /* Fix fontSize 11/06/2019 */
    font-size: .8rem;
}

.content-wrapper{
    background-color: white;
}



/* General config */


.txtRed {
    color: red;
  }
  .txtYellow {
   
  color: #b37300 !important ;

  }
  
  .btn {
    padding: 0.3rem 0.4rem;
    font-size: .7rem;
}


 .modal{
        .btn {
      
            font-size: 1rem;
        }
  }

.form-control {
    height: calc(1.7rem + 2px);
    padding: 0.3rem 0.5rem;
    font-size: .7rem;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(1.7rem + 2px);
    padding: 0.3rem 0.5rem;
    font-size: .7rem;
}

/* General config */

/* 
    ##### #   # #####   ####  
      #    # #  #    # #    # 
      #     #   #    # #    # 
      #     #   #####  #    # 
      #     #   #      #    # 
      #     #   #       ####  
*/
h5 { 
    font-size: 100%;
}

/* .page__card {
    border: none;
    height: auto;
    min-height: 85vh;
    padding: 1.5rem;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);
    border-radius: 4px;
    background-color: #fff;
}

.page__card::before{
    content: "";
    position: fixed;
    width: 30px;
    height: 30px;
    z-index: 5000;
    top: var(--arrowHeight);
    left: 11.4em;
    margin-left: -0.79em;
    box-sizing: border-box;
    border: 1em solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -3px 3px 3px 0 rgba(212, 212, 212, 0.329);
} */

.page__title { 
    font-size: 28px;
    font-weight: bold;
}
.page__subtitle {
    font-size: 15px;
    margin-bottom: 0 ;
    font-weight: 300;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 12px;
    color: #f4f4f4;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 12px;
    color: #f4f4f4;
}

::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 12px;
    color: #f4f4f4;
}

/* 
     ####   ####  #       ####  #####  
    #    # #    # #      #    # #    # 
    #      #    # #      #    # #    # 
    #      #    # #      #    # #####  
    #    # #    # #      #    # #   #  
     ####   ####  ######  ####  #    # 
*/
.primary__color { 
    color: #036DFD;
}
/*                     
    #####  ##### #    # 
    #    #   #   ##   # 
    #####    #   # #  # 
    #    #   #   #  # # 
    #    #   #   #   ## 
    #####    #   #    # 
*/
.btn-pill {
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.btn-main {
    background: linear-gradient(to right, #0072ff, #00adff);
}

.btn:focus, .btn:active:focus, .btn.active:focus{
    outline:none !important;
    box-shadow:none !important;
}

/* 
    #    #   ##   #    # 
    ##   #  #  #  #    # 
    # #  # #    # #    # 
    #  # # ###### #    # 
    #   ## #    #  #  #  
    #    # #    #   ##   
*/
.navbar__shadow {
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    background: #bcbcbc;
    border: solid 3px #f0f0f0;
    box-shadow: 0 2px 3px rgba(22, 16, 16, 0.3);
    object-fit: cover;
    object-position: 0% 19%;
}

.border__primary {

  border: solid 3px #036DFD;
}

.round8 { 
  border-radius: 8px;
}

.square40px {
  width: 40px;
  height: 40px;
  overflow: hidden;
}


.square80px {
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.square40px__img {
  height: 100%;
}

.navbar-detail {  
    min-width: 320px;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    border: none;
}
.navbar-detail .fullname { 
    font-weight: bold;
    font-size: 15px;
    padding: 8px;
}

.navbar-detail .lstModel { 
    font-size: 11px;
    color: #6c757d;
    padding: 0px 0px 0px 8px;

}

.navbar-detail .position { 
    font-size: 0.875rem;
    color: #6c757d;
    padding: 8px;
    padding-bottom: 0;
}
.navbar-detail .email { 
    font-size: 0.875rem;
    padding: 0 8px 0px 8px;
    color: #0072ff;
}


/*                                               
    ####  # #####  ######    #    #   ##   #    # 
    #      # #    # #         ##   #  #  #  #    # 
     ####  # #    # #####     # #  # #    # #    # 
         # # #    # #         #  # # ###### #    # 
    #    # # #    # #         #   ## #    #  #  #  
     ####  # #####  ######    #    # #    #   ##   
*/

.new-main-sidebar{
    width: 126px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5000;
    /* background: #21242F; */
    background: #00447b;
    

    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* box-shadow: 0 0 5px rgba(66, 66, 66, 0.25), 0 0px 0px rgba(66, 66, 66, 0.22); */
}

.new-main-sidebar::-webkit-scrollbar {
   display: none;
}

.new-sidebar{
    padding-left: 0;
    padding-right: 0;
    margin-top: 2.3rem
}

.new-sidebar .nav-item{
    width: 100%;
    padding-top: 35px;
    padding-bottom: 20px;
    border-top: solid 2px #003661;
}

.nav .nav-treeview{
    display:inherit;
}
.new-sidebar .nav-child-item{
    position: relative;
}

.new-sidebar .nav-child-item .new-nav-link{
    /* background: #00447b; */
    background :#00447b;
   

    transition: background-color .5s;
}

.new-sidebar .nav-child-item .new-nav-link:hover{
    /* background: #00447b; */

    background :#00447b;
    background: -moz-linear-gradient(left, #00447b 0%, rgba(0,68,123,0.77) 30%, rgba(228,228,228,0.3) 92%, rgba(237,237,237,0.3) 95%, rgba(237,237,237,0.3) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #00447b), color-stop(30%, rgba(0,68,123,0.77)), color-stop(92%, rgba(228,228,228,0.3)), color-stop(95%, rgba(237,237,237,0.3)), color-stop(100%, rgba(237,237,237,0.3)));
    background: -webkit-linear-gradient(left, #00447b 0%, rgba(0,68,123,0.77) 30%, rgba(228,228,228,0.3) 92%, rgba(237,237,237,0.3) 95%, rgba(237,237,237,0.3) 100%);
    background: -o-linear-gradient(left, #00447b 0%, rgba(0,68,123,0.77) 30%, rgba(228,228,228,0.3) 92%, rgba(237,237,237,0.3) 95%, rgba(237,237,237,0.3) 100%);
    background: -ms-linear-gradient(left, #00447b 0%, rgba(0,68,123,0.77) 30%, rgba(228,228,228,0.3) 92%, rgba(237,237,237,0.3) 95%, rgba(237,237,237,0.3) 100%);
    background: linear-gradient(to right, #00447b 0%, rgba(0,68,123,0.77) 30%, rgba(228,228,228,0.3) 92%, rgba(237,237,237,0.3) 95%, rgba(237,237,237,0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00447b', endColorstr='#ededed', GradientType=1 );
    transition: background-color 1s;
}

.nav-arrow{
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    z-index: 5000;
    top: .16rem;
    left: 11.5em;
    margin-left: 0em;
    box-sizing: border-box;
    border: .7em solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    /* box-shadow: 0px 3px 3px 0 rgba(212, 212, 212, 0.329); */
}

.navbar-menu{
    display: none;
}

/* @media (max-width: 767.98px) {

    .brand-frame{
        display: none;
    }

    .navbar-menu{
        display: initial;
    }

    .new-sidebar {
        margin-top: 0rem;
    }

    .sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .main-footer, .sidebar-mini.sidebar-collapse .main-header{
        width: 100%;
        z-index: 5000;
        // padding: .25rem 1rem; 
        margin-left: 0rem !important;
    }

    .new-main-sidebar{
        width: 100%;
        position: fixed;
        background: none;
        top: 64px;
        height: fit-content;
        z-index: 4800;
        transition: top 1s ease;
    }

    .new-sidebar .nav-item{
        display: flex;
        align-items: center;
        background: #00447b;
        padding: 10px;
        // padding-top: 10px;
        // padding-bottom: 10px;
    }
} */

/* ================================================= */

/* .sidebar {
    padding-left: 0;
    padding-right: 0;
}
.sidebar .btn.nav-link{ 
    width: 100%;
}
.main-sidebar {
    z-index: 1001;
    background: #fff;
    box-shadow: 0 0 5px rgba(66, 66, 66, 0.25), 0 0px 0px rgba(66, 66, 66, 0.22);
}
.main-sidebar .brand-link { 
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);
    background: #00c6ff;
    background: -webkit-linear-gradient(to right, #0072ff, #00adff);
    background: linear-gradient(to right, #0072ff, #00adff);
}
.main-sidebar .brand-text {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .6px;
}

.main-sidebar .nav-item .nav-link{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    min-height: 50px;
    display: flex;
    align-items: center;
    transition: all .3s ease;
}

.main-sidebar .nav-item .nav-link:hover{
    border-left: 3px solid #0072ff;
    background:  linear-gradient(to right, rgba(0 ,173 ,255 ,.3), #00aeff00);
    border-radius : 0;
}

.nav-sidebar .nav-link > p > .right {
    top:20px;
}

.nav-treeview { 
    background: #eef0f3; 
}

.nav-treeview p { 
    font-size: 14px;
    font-weight: normal;
}

@media (min-width: 992px) { 
    .sidebar-mini.sidebar-collapse .main-sidebar, .sidebar-mini.sidebar-collapse .main-sidebar:before {
        margin-left: 0;
        width: 4rem;
    }
}
@media (min-width: 992px) {
    .sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .main-footer, .sidebar-mini.sidebar-collapse .main-header {
        margin-left: 4rem !important;
    }
} */

/* 
    #     #    #    ### #     # 
    ##   ##   # #    #  ##    # 
    # # # #  #   #   #  # #   # 
    #  #  # #     #  #  #  #  # 
    #     # #######  #  #   # # 
    #     # #     #  #  #    ## 
    #     # #     # ### #     # 
*/
.content-wrapper {
    padding: 20px;
}

.text__bold { 
    font-weight: bold !important;
}
.text__light { 
    font-weight: lighter !important;
}
/* 
     ####    ##   #####  #####  
    #    #  #  #  #    # #    # 
    #      #    # #    # #    # 
    #      ###### #####  #    # 
    #    # #    # #   #  #    # 
     ####  #    # #    # #####  
*/

.card { 
    border: none;
    box-shadow:0px 0px 5px rgba(66, 66, 66, 0.2);
}

/*                                                                
     ####   ####  #####   ####  #      #      #####    ##   #####  
    #      #    # #    # #    # #      #      #    #  #  #  #    # 
     ####  #      #    # #    # #      #      #####  #    # #    # 
         # #      #####  #    # #      #      #    # ###### #####  
    #    # #    # #   #  #    # #      #      #    # #    # #   #  
     ####   ####  #    #  ####  ###### ###### #####  #    # #    # 
*/

::-webkit-scrollbar {
    height: 16px;
    width: 16px;
}
::-webkit-scrollbar {
    height: 12px;
    width: 12px;
}
::-webkit-scrollbar {
    height: 8px;
    overflow: visible;
    width: 8px;
}
::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}
body::-webkit-scrollbar-corner {
    background-clip: padding-box;
    background-color: #f5f5f5;
    border: solid #fff;
    border-width: 3px 0 0 3px;
    box-shadow: inset 1px 1px 0 rgba(0,0,0,.14);
}
::-webkit-scrollbar-corner {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #dadce0;
    border-color: transparent;
    border-radius: 8px;
    border-style: solid;
    border-width: 4px;
    box-shadow: none;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
    border-width: 4px!important;
}
body::-webkit-scrollbar-thumb {
    border-width: 1px 1px 1px 5px;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
    border-width: 0!important;
}
::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px 1px 1px 6px;
    min-height: 28px;
    padding: 100px 0 0;
}
::-webkit-scrollbar-track {
    box-shadow: none;
    margin: 0 4px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track-piece {
    border-width: 4px!important;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
    border-width: 0!important;
}
::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px;
}
body.docs-homescreen-google-material-update::-webkit-scrollbar-track-piece {
    background-color: #fff;
    border: none;
    box-shadow: none;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
    border-width: 4px!important;
}
body::-webkit-scrollbar-track-piece {
    background-clip: padding-box;
    background-color: #f5f5f5;
    border: solid #fff;
    border-width: 0 0 0 3px;
    box-shadow: inset 1px 0 0 rgba(0,0,0,.14), inset -1px 0 0 rgba(0,0,0,.07);
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
    border-width: 0!important;
}


.loading-indicator::after {
    content: '';
    border: 5px solid #c8e5ff;
    border-radius: 50%;
    border-top: 5px solid #0072ff;
    width: 45px;
    height: 45px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 5px;
    z-index: 99999999;
}





.loading-normal::after {


    content: ''; 
    border: 5px solid #c8e5ff;      
    border-radius: 50%;
    border-top: 5px solid #0072ff;
    width: 45px;
    height: 45px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  position: fixed;
  transform: translate(-50%, -50%);
    z-index: 99999998;
} 





.div-loading::after {

    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    z-index: 99999999;
    /* background-color: rgba(0,0,0,0.3) !important; */


    
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* ไม่ให้ show กากบาท  ที่ invalid input */
.form-control.is-invalid.nonImage{
   
    background-image : none  !important ; 
}


