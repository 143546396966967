.SpecialProgram {
  // font-size: 13px;

  .izjNNF {
    width: 17px;
    height: 17px;
  }

  .ftXlQl {
    width: 17px;
    height: 17px;
  }

  .border-gray {
    border-color: #dbdee0;
  }

  .success_btn {
    color: rgb(0, 177, 0);
  }

  .success_btn:hover {
    color: green;
  }

  .danger_btn {
    color: red;
  }

  .danger_btn-del:hover {
    color: rgb(199, 0, 0);
  }

  //Adje

  #programDDLTD .col-11 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  #programDDLTD .col-md-11 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  #programDDLTD .col-1 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  #programDDLTD .col-md-1 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
