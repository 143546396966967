.period_list {
    input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .react-datepicker__input-container input:first-child{
        max-width: 110px;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
    }

    .add-color{
        color:rgb(0, 177, 0);
    }

    .add-color{
        color:green;
    }

    .del-color{
        color:red;
    }

    .del-color:hover{
        color:rgb(199, 0, 0);
    }

    .csp__react-numeric{
        border-radius: 4px;
        border: solid 1px #c9d0d0;
        width: 100%;
        padding: 5px;
        font-size: 17px;
        color: #353535;
    }
}

.period_detail {
    font-size: 1.05rem;
    .dataLable{
        color:rgb(140, 140, 140)
    }
}


.subscription_detail {
    font-size: 1.05rem;
    .dataLable{
        color:rgb(140, 140, 140)
    }
}

