

.descBox{
 // border: solid 1px #cccccc;

table{
  border: 0px;
    th, td {
      font-size: 0.7rem;
        color: #808080;
        padding:4px  0px 0px 10px; 
        text-align: right;
        border: 0px;

      
      }
    }

   
}  