.ticketdetail{
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;

    // background-size: -300px -100px;
    background: url('../../../../img/towerGray.png');
    background-repeat: repeat-x;
    background-size: 100%;
    background-position: left bottom;
}

.ticketdetail{

    $bgcard-color: #f7f7f7;

    // background: url('../../../"img/towerGray.png');

   .LstFilesMain {


    .btnChoose{
      width :50%;      
     
    }    
}






    .control{
        max-width:500px;
        margin-top: 10px;

    
        

        
        .CustomSelectModalPanel{
            max-width:400px;
        }
            
        label{
            font-size:1.2em;
            margin-bottom: 2px;
        }
        


    }

   



    label{
        margin-bottom: 0px;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Opera and Firefox */
    }

    .helpDetail-icon{
        font-size: 45px;
       color:rgb(80, 190, 194);
    }

    //Global
    .ticketdetail{
        display: flex;
        justify-content: center;
    }

    .ticketdetail_infoCard{
        flex: 1; 
        max-width: 1100px;
        border-radius: 4px;
        box-shadow: 4px 4px 2px 0px rgba(133,133,133,1);
      //  margin-bottom: 20px;
    }

    .helpDetail-cardHeader{
        display: flex;
        flex-direction: row;
        background-color: #00447b;

        padding-top: 5px;
        padding-bottom: 5px;

        border-radius: 1px 1px 0px 0px;
        border-bottom: solid 5px #006377;

        margin-top: -20px;
        // margin-left: -20px;
        // margin-right: -20px;
        // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.6);
    }

    .helpDetail-cardBody{
        // padding: 10px;
        // background-color: #f7f7f7;
        // box-shadow: 2px 0px 2px 1px rgba(0,0,0,0.6);
    }

    .helpDetail-cardFrame{
        height: 41px;
        display: flex;
        align-content: center;
        background-color: #00447b;
        // box-shadow: 2px 2px 2px 1px rgba(0,0,0,0.6);
    }

    // Header

    @mixin cardHeader-fontSize{
        font-size: calc((.25em + .4vmin) + (.25em + .4vmax));
    }

    @mixin cardHeader-margin{
        margin-top: 2.5px;
        margin-bottom: 2.5px;
    }

    @mixin cardHeader-textColor{
        color: #ffffff;
        text-shadow: 1px -1px 1px #272727, -1px 1px 1px #424242;
    }

    .helpDetail-cardHeader-docNo {
        // @include cardHeader-fontSize;
        font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
        
        @include cardHeader-textColor;
        @include cardHeader-margin;
    }

    .helpDetail-cardHeader-status {
        @include cardHeader-textColor;
        @include cardHeader-fontSize;
        @include cardHeader-margin;
    }

    .helpDetail-cardHeader-requester {
        @include cardHeader-textColor;
        @include cardHeader-fontSize;
        @include cardHeader-margin;
    }

    .helpDetail-cardHeader-requestDate{
        @include cardHeader-textColor;
        @include cardHeader-fontSize;
        @include cardHeader-margin;
    }

    .wrapperInfoCard{
        margin-top: 2rem;
        margin-left: 3rem;
        margin-right: 1rem;
    }

    .wrapperLine{
        display: flex;
        justify-content: center;
        background-color:  $bgcard-color;
    }

    .helpDetail-cardBody--line{
        min-width: 95%;
        border-bottom: solid 2px rgb(223, 223, 223);
    }

    .helpDetail-cardBody--opener{
        display: flex;
    }

    .helpDetail-cardBody--operator{
        display: flex;
    }

    .helpDetail-cardBody--lableOpener{
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        color:white;
        text-align: center;
        background-color: #05006d;
        font-size: 1.2em;
        font-weight: bold;
        text-shadow: -1px -1px 1px #6d6d6d, 2px 2px 1px #060606;
        box-shadow: 0px 3px 1px 0px rgba(101, 101, 101, 0.75);

        // writing-mode:tb-rl;
        // transform: rotate(180deg);
        writing-mode: vertical-rl;
        text-orientation: upright;

        margin-left: -26px;

        padding-top: .2rem;
        padding-bottom: .2rem;
        padding-left: .2rem;
        padding-right: .2rem;
    }

    .helpDetail-cardBody--lableOperator{
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        color:white;
        text-align: center;
        // background-color: #006d7b;
        background-color: #8F3985;
        font-size: 1.2em;
        font-weight: bold;
        text-shadow: -1px -1px 1px #7b7b7b, 2px 2px 1px #313131;
        box-shadow: 0px 3px 1px 0px rgba(101, 101, 101, 0.75);
        writing-mode: vertical-rl;
        text-orientation: upright;

        margin-left: -26px;

        padding-top: .2rem;
        padding-bottom: .2rem;
        padding-left: .2rem;
        padding-right: .2rem;
    }

    //For ie

    .helpDetail-cardBody--lableOpener_ie{
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        color:white;
        text-align: center;
        background-color: #05006d;
        font-size: 1.2em;
        font-weight: bold;
        text-shadow: -1px -1px 1px #6d6d6d, 2px 2px 1px #060606;
        box-shadow: 0px 3px 1px 0px rgba(101, 101, 101, 0.75);

        writing-mode:tb-rl;
        transform: rotate(180deg);
        // writing-mode: vertical-rl;
        // text-orientation: upright;

        margin-left: -26px;

        padding-top: .2rem;
        padding-bottom: .2rem;
        padding-left: .2rem;
        padding-right: .2rem;
    }

    .helpDetail-cardBody--lableOperator_ie{
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        color:white;
        text-align: center;
        // background-color: #006d7b;
        background-color: #8F3985;
        font-size: 1.2em;
        font-weight: bold;
        text-shadow: -1px -1px 1px #7b7b7b, 2px 2px 1px #313131;
        box-shadow: 0px 3px 1px 0px rgba(101, 101, 101, 0.75);

        writing-mode:tb-rl;
        transform: rotate(180deg);
        // writing-mode: vertical-rl;
        // text-orientation: upright;

        margin-left: -26px;

        padding-top: .2rem;
        padding-bottom: .2rem;
        padding-left: .2rem;
        padding-right: .2rem;
    }

    .ticketdetail_infoCard_ie{
        flex: 1; 
        border-radius: 4px;
        box-shadow: 4px 4px 2px 0px rgba(133,133,133,1);
    }

    .wrapperChat_ie{
        flex: 1;
        margin-left: 3rem;
        margin-right: 1rem;
    }

    .helpDetail-fontSize{
        font-size: 0.85rem;
    }

    //For ie

    .helpDetail-cardBody--contentOpener{
        flex: 1;
        background-color: $bgcard-color;
        padding-top: 10px;
        padding-bottom: 2px;
        // padding-left: 2px;
        // padding-right: 2px;
    }

    .helpDetail-cardBody--contentOperator{
        flex: 1;
        background-color: $bgcard-color;
        padding-top: 2px;
        padding-bottom: 15px;
        // padding-left: 2px;
        // padding-right: 2px;
    }

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    .ticketdetail_historyCard{
        top: 30%;
        right: 0%;
        z-index: 100;
         min-width: 300px;
         max-width: 400px;
        // min-height: 300px;
        // max-height: 500px;
         width: 150%;
        // height: 100%;
        height: 71%;
        position: fixed;

        //#bebebe
        background: #6C7A89;
        border-radius: 0px 0px 0px 5px;
        padding-bottom:30px ;
    }

    .ticketdetail_historyCard--btn{
        cursor: pointer;
        position: absolute;
        color:white;
        text-align: center;
        //    //#bebebe
        background-color: #6C7A89;
        // font-size: 1.3em;
        font-size: calc((.2em + .4vmin) + (.2em + .4vmax));
        letter-spacing: 4px;
        font-weight: bold;
         text-shadow: -1px -1px 1px #777777, 2px 2px 1px #56616c;
        //text-shadow: -1px -1px 1px  #343a42, 2px 2px 1px #4a545e;
        // box-shadow: 0px 3px 1px 0px rgba(101, 101, 101, 0.75);
        // writing-mode: vertical-rl;
        // text-orientation: upright;
       
        margin-left: -26px;

        padding-top: .94rem;
        padding-bottom: .8rem;
        padding-left: .2rem;
        padding-right: .2rem;

        writing-mode:tb-rl;
        transform: rotate(180deg);
        border-right: 4px solid #777777;
        // text-decoration: underline;
    }

    .ticketdetail_historyBox{
        margin-top: 13px;
        margin-bottom: 13px;
        margin-left: 13px;
        margin-right: 13px;

        width: 95%;
        height: 97%;
        overflow-y: scroll;
        overflow-x: hidden;

        color:white;
    }

    .historyLable{
        font-size: .8rem;
       white-space: pre-line
    }
    .historyLable.desc{
        font-size: .8rem;
        color:#f9fafb;

      
    }
    .historyLable.comment{
        font-size: .7rem;
        color:#cfd4d9;
    }

    .historyUnderLine{
        margin-top: 8px;
        margin-bottom: 8px;
        width:95%;
        border-bottom: 1px solid #a4adb7;
    }
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    .wrapperSubmitBtn{
        padding-left: 15px;
        padding-bottom: 10px;
        background-color: $bgcard-color;
    }

    .wrapperChat{
        flex: 1;
        margin-left: 3rem;
        margin-right: 1rem;
        max-width: 1100px;
    }

    // Media
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) { 
        
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        .helpDetail-cardHeader{
            // margin-top: -20px;
            margin-top: -11px;
            // margin-left: -20px;
            // margin-right: -20px;
        }

        .wrapperInfoCard{
            margin-top: .5rem;
            margin-left: 0rem;
            margin-right: 0rem;
        }
        
        .ticketdetail_infoCard{
            // max-width: 800px;
            box-shadow: 0px 0px 0px 0px rgb(255, 255, 255);
            // border-radius: 0px 0px 4px 4px;
            margin-top: 0px;
         //   margin-bottom: 20px;
        }

        .helpDetail-cardBody--line{
            display: none;
        }

        .helpDetail-cardBody--opener{
            flex-direction: column;
        }
    
        .helpDetail-cardBody--operator{
            flex-direction: column;
        }

        .helpDetail-cardBody--lableOpener{
            margin-left: 0px;
            border-top-left-radius: 0rem;
            border-bottom-left-radius: 0rem;
            box-shadow: 0px 0px 0px 0px rgba(101, 101, 101, 0.75);
            writing-mode: horizontal-tb;
        }
    
        .helpDetail-cardBody--lableOperator{
            margin-left: 0px;
            border-top-left-radius: 0rem;
            border-bottom-left-radius: 0rem;
            box-shadow: 0px 0px 0px 0px rgba(101, 101, 101, 0.75);
            writing-mode: horizontal-tb;
        }

        .helpDetail-cardBody--opener{
            margin-top: 20px;
            box-shadow: 4px 4px 2px 0px rgba(133,133,133,1);
        }

        .helpDetail-cardBody--operator{
            margin-top: 20px;
            box-shadow: 4px 4px 2px 0px rgba(133,133,133,1);
        }

        .helpDetail-cardFrame{
            display: none;
        }

        .wrapperSubmitBtn{
            padding-right: 0px;
            padding-bottom: 0px;
            margin-top: 10px;
            background-color: #fff;
        }

        .ticketdetail_historyCard--btn{
            letter-spacing: 3.5px;
            margin-left: -25px;
            padding-top: .7rem;
            padding-bottom: .5rem;
        }

        .submitBtn{
            box-shadow: 4px 4px 2px 0px #858585;
        }

        .wrapperChat , .wrapperChat_ie{
            margin-left: 0rem;
            margin-right: 0rem;
        }
    }

    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) { 

    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) { 

    }
}