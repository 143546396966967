


.LstFilesMain {

  //  width:300px ; 
    .lstUploadTable{
        th, td {
         
            padding:0px 8px ; 

          }
       
    }  
    
    .btnChoose {
      width :100%;      
      font-size :1.2em;
    }


   
}

.LstFiles {
    width:100%;
     display : flex ; 
    flex-direction: column;
}

.LstFilesItem  .preview{
  
   height : 25px ;

   background-color : #F0F0F0;
   overflow: hidden;
   position: relative;
}





.LstFilesItem  .preview img{
    position: relative;
    margin: -50% auto;/* virtualy height needed turn don to zero */
    width: 100%;
    height:auto;
    vertical-align:middle;
 
 }
 .LstFilesItem  .preview .no-item{
    display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
 }

 .LstFilesItem  .preview .no-item a{
    color: #003661;
  
     }


 .LstFilesItem  .preview .no-item a:hover{
//color:red ;
     text-decoration : none ; 
 }





.LstFilesItem  .bar{
  
    display : flex ; 

}

.LstFilesItem .bar.client {
  

    background-color : #d2e1f0  ;


}
.LstFilesItem .bar.server {
  

    background-color : #e6e6e6;


}

.LstFilesItem .bar .FileName {
  
    font-size:1.1em  ; 
    // width:150px !important;
    color : black;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;




}


















