#sidebar {
  height: calc(100% + 40px);
  min-height: 100vh;
  background-color: #333;
 /* font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: bold;
  margin: -20px;
  padding-left : 0.3em; 
  padding-right : 0.3em;  
 


}

#sidebar .list-group-item {

  font-size: 1.3em;
  border-radius: 0;
  background-color: #333;
  color: #ccc;
  border-left: 0;
  border-right: 0;
  border-color: #2c2c2c;
  /*white-space: nowrap;*/
  text-decoration: none;
  /* padding:  1.2em; */

  padding: 10px 6px;


}

.ticketactive{
  background-color:rgb(89, 187, 228) !important;
}

.ticketList:parent {
  padding:0px !important;
}

.ticketList .list {


  margin: 0px 5px !important;


}



.ticketactive span{
  color: black;
}

/* highlight active menu */
#sidebar .list-group-item:not(.collapsed) {
  background-color: #222;
}

/* closed state */
#sidebar .list-group .list-group-item[aria-expanded="false"]::after {
/* content: " \f0d7"; */
font-family: FontAwesome;
display: inline;
text-align: right;
padding-left: 5px;
}

/* open state */
#sidebar .list-group .list-group-item[aria-expanded="true"] {
background-color: #222;
}
#sidebar .list-group .list-group-item[aria-expanded="true"]::after {
/* content: " \f0da"; */
font-family: FontAwesome;
display: inline;
text-align: right;
padding-left: 5px;
}

/* level 1*/
#sidebar .list-group .collapse .list-group-item  {
padding-left: 20px;
}

/* level 2*/
#sidebar .list-group .collapse > .collapse .list-group-item {
padding-left: 30px;
}

/* level 3*/
#sidebar .list-group .collapse > .collapse > .collapse .list-group-item {
padding-left: 40px;
}

@media (max-width:48em) {
  /* overlay sub levels on small screens */
  #sidebar .list-group .collapse.in, #sidebar .list-group .collapsing {
      position: absolute;
      z-index: 1;
      width: 190px;
  }
  #sidebar .list-group > .list-group-item {
      text-align: center;
      padding: .75rem .5rem;
      min-width: 39px;
  }
  /* hide caret icons of top level when collapsed */
  #sidebar .list-group > .list-group-item[aria-expanded="true"]::after,
  #sidebar .list-group > .list-group-item[aria-expanded="false"]::after {
      display:none;
  }
}

/* change transition animation to width when entire sidebar is toggled */
#sidebar.collapse {
-webkit-transition-timing-function: ease;
     -o-transition-timing-function: ease;
        transition-timing-function: ease;
-webkit-transition-duration: .2s;
     -o-transition-duration: .2s;
        transition-duration: .2s;
}

#sidebar.collapsing {
opacity: 0.8;
width: 0;
-webkit-transition-timing-function: ease-in;
     -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
-webkit-transition-property: width;
     -o-transition-property: width;
        transition-property: width;

}

#tableTicketList tbody tr:last-child{
  border-bottom:1px solid #dee2e6;
}