


.fullDiv{
    display: flex;
    height: 100%;
    width: 100%; 
    align-items: center;
    justify-content: center;




}



.MetabaseReportCloundServiceTag{
   // display: none;
  //  background-color: pink;






    .Icon {
    //    display: none  !important;

    }

    // html{

    //     display: none;

    // }
            .fullscreen-normal-text{
                display: none  !important;

            }

            div{
                display: none  !important;
                overflow: auto !important;
                width: auto !important;
            }
    


}