
.mfa_form{
    .txtBold{
        font-weight: 900;


    }

    color: gray;
    font-weight: bolder;
    .inputNum{
        width: 60px;
        border: none;
        border-bottom: 3px solid rgba(0, 0, 0, 0.3);
        border-radius: 0px;
         margin: 0 10px;
        text-align: center;
        font-size: 35px;
      //  margin: 20px 10px 0 10px;
       // margin: 5px;
        
        // cursor: not-allowed;
        // pointer-events: none;
    }


}

.wrapper__login  {
    color: #ccc;
    font-weight: 300;

    .containerIconTogglePassword {
        position: relative;
        color: lightgray;
        cursor: pointer;

    
            .toggle-password {
                // float: right;
                right: 12px;
                top: 17px;
                position: absolute;
                z-index: 2;
            }
         
    

      
    }


}
.wrapper__login  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #ccc;
    font-weight: 300;
}
.wrapper__login  :-moz-placeholder {
    color: #ccc;
    opacity: 1;
    font-weight: 300;
}
.wrapper__login  ::-moz-placeholder {
    color: #ccc;
    opacity: 1;
    font-weight: 300;
}
.wrapper__login :-ms-input-placeholder {
    color: #ccc;
    font-weight: 300;
}
.wrapper__login {
    background: #50a3a2;
    background: linear-gradient(to bottom right, #00adff 0%, #0072ff 100%);
    position: absolute;
    /* top: 50%; */
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.wrapper__login.form-success .container h1 {
    -webkit-transform: translateY(85px);
    transform: translateY(85px);
}
.login__left { 
    // background: url('../../../img/building-msc.jpg');
    //background-color: rgb(0, 40, 72);
    background-color:#005294;



    background-position: 44% 40%;
    border-top-left-radius:  0.25rem ;
    border-bottom-left-radius:  0.25rem ;

    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
}

.login__left h1{ 
    color: white;
    text-shadow: 1px 1px 1px #333;
}
.login__left h4{
    font-weight: light;
    font-size: 15px;
    color: #f4f4f4;
}

.login__right {
    display: flex;
    align-items: center;
    justify-content: center;
}


.login__right {

    .box {
        color:#343a40;
        font-size: 120%;
        padding: 20px;
        background-color: rgba(86,61,124,.15);
       // border:1px solid #343a40;
    }


   ::placeholder {
 
 font-size: 1.2rem
  }
  
//   :-ms-input-placeholder { /* Internet Explorer 10-11 */
//    color: red;
//    font-size: 5rem
//   }
  
//   ::-ms-input-placeholder { /* Microsoft Edge */
//    color: red;
//    font-size: 5rem
//   }





.txtLink{
    cursor:pointer;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.txtLink:hover{
    color: #0056b3;
    text-decoration: underline;
}

}




.container {
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 80px 0;
    height: 700px;
    text-align: center;
}
.container h1 {
    font-size: 40px;
    transition-duration: 1s;
    transition-timing-function: ease-in-put;
    font-weight: 200;
}
.card__login { 
    height: 100%;
    background: white;
}
.login__form {
    text-align: center;
    padding: 20px 0;
    position: relative;
    z-index: 2;
}


.login__form{
    .container{
        margin: 20px auto 0 auto;

    }
    
    
    input {
   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: #f4f4f4;
    // width: 320px;
    width:100%;
    border-radius: 3px;
    padding: 10px 15px;

  //  margin: 20px auto 0 auto;
  //  margin: 0 auto 20px auto;
    display: block;
    text-align: center;
    font-size: 17px;
    color: #ccc;
    -webkit-text-fill-color: #ccc;
   // transition-duration: 0.25s;
    font-weight: 300;

}

}
.login__form input:hover {
    background-color: #ececec;
}
.login__form input:focus {
    background-color: #ececec;
    //width: 380px;
    color: #0072ff;
    -webkit-text-fill-color: #0072ff;
}
.login__form button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    /* background-color: white; */
    border: 0;
    padding: 10px 15px;
    /* color: #0072ff; */
    border-radius: 3px;
    // width: 320px;
    width: 100%;
    cursor: pointer;
    font-size: 18px;
  //  transition-duration: 0.25s;
  margin: 20px auto 0 auto;

  
}


.login__form .validate.is-invalid { 
    border-color: #dc3545;
    padding-right: 2.25rem;
}

// BG
.bg-oncepic{
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.bg-bubbles li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -160px;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;
    transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
    left: 10%;
}
.bg-bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 17s;
    animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
    animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
    left: 70%;
}
.bg-bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
    animation-delay: 15s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
    animation-delay: 11s;
}
@-webkit-keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-700px) rotate(600deg);
        transform: translateY(-700px) rotate(600deg);
    }
}
@keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-700px) rotate(600deg);
        transform: translateY(-700px) rotate(600deg);
    }
}
