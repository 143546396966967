/* Cool infinite background scrolling animation.
 * Twitter: @kootoopas
 */

/* Exo thin font from Google. */
 //@import url(https://fonts.googleapis.com/css?family=Exo:100);

/* Background data (Original source: https://subtlepatterns.com/grid-me/) */
$bg-url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC";
$bg-width: 50px;
$bg-height: 50px;

/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% { background-position: $bg-width $bg-height; }
}
@-moz-keyframes    bg-scrolling-reverse {
  100% { background-position: $bg-width $bg-height; }
}
@-o-keyframes      bg-scrolling-reverse {
  100% { background-position: $bg-width $bg-height; }
}
@keyframes         bg-scrolling-reverse {
  100% { background-position: $bg-width $bg-height; }
}

@-webkit-keyframes bg-scrolling {
  0% { background-position: $bg-width $bg-height; }
}
@-moz-keyframes    bg-scrolling {
  0% { background-position: $bg-width $bg-height; }
}
@-o-keyframes      bg-scrolling {
  0% { background-position: $bg-width $bg-height; }
}
@keyframes         bg-scrolling {
  0% { background-position: $bg-width $bg-height; }
}

/* Main styles */
.loginBG {
    width: 100%;
    height: 100%;
    // margin-top: 13.5rem;
    color: #999;
    font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
    text-align: center;
    /* img size is 50x50 */
    background: url($bg-url) repeat 0 0;
    
    -webkit-animation: bg-scrolling-reverse .92s infinite; /* Safari 4+ */
    -moz-animation:    bg-scrolling-reverse .92s infinite; /* Fx 5+ */
    -o-animation:      bg-scrolling-reverse .92s infinite; /* Opera 12+ */
    animation:         bg-scrolling-reverse .92s infinite; /* IE 10+ */
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function:    linear;
    -o-animation-timing-function:      linear;
    animation-timing-function:         linear;
    
    // &::before {
    //     content: "INFINITY";

    //     font-size: 8rem;
    //     font-weight: 100;
    //     font-style: normal;
    // }
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login_text1{
    margin: 10px;
    // font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
    font-size: 13rem;
    font-weight: bold;
}

.login_text2{
    margin: 10px;
    font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
    font-size: 7rem;
}

.login_text3{
    margin: 10px;
    font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
    font-size: 7rem;
}

.login-canvas{
  // background-color: rgb(255, 0, 0);
  // background: url("../img/bg_login.png");
  // background-repeat: no-repeat;
  // background-position: right bottom;
  // height: 100%;
  // widows: auto;
  // background-size: 100% auto;
  display: flex;
  flex-direction: column-reverse;
}

.login-canvas-bg1{
  width:100%;
  // height: 85%;
  // background-color:#0b0f90;
}

.login-canvas-bg2{
  width:100%;
  height: 15%;
  background-color: white;
}

.login-canvas-text1{
  position: absolute;
  top: 1.3rem;
  left: 9.1rem;
  // top: calc((1em - 3.7vmin) + (1em - 7.5vmax));
  // left: calc((1em + .4vmin) + (1em + .6vmax));
  color: white;
  font-weight: bold;
  font-size: 5rem;
  // font-size: calc((1em + 3vmin) + (.75em + 2.45vmax));
  // font: 400 5rem/1.5 exo, ubuntu, "Noto Sans JP", helvetica, arial, sans-serif;
  // font-family: NotoSansJP;
}

.login-canvas-text2{
  position: absolute;
  top: 7rem;
  left: 5.5rem;
  // top: calc((1em - 2vmin) + (1em + 1vmax));
  // left: calc((1em + .4vmin) + (1em + .6vmax));
  color: #dfdfdf;
  font-weight: bold;
  font: 400 2.6rem/1.9 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  // font: 400 calc((.5em + 2vmin) + (.5em + 2vmax))/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
}

.login-canvas-logo1{
  position: absolute;
  top: 3rem;
  left: 3rem;
  // top: calc((1em + 2vmin) + (1em + 2.7vmax));
  // left: calc((1em + 2vmin) + (1em + 2vmax));
  height: 60px;
  width: 95px;
  // width: calc((.4em + 2vmin) + (.7em + 5vmax));
  // height: calc((.5em + 2vmin) + (.5em + 2vmax));
}

.login-canvas-logo2{
  width:auto;
  height:100%;
}

.login-canvas-wrapperLogo1{
  
}

.login-canvas-wrapperLogo2{
  position: absolute;
  bottom: 0;
  right: 0;
  height: 64%;
}

@media (max-width: 1200px) {
  .login-canvas-text2{
    top: calc((1em - 1vmin) + (1em + 3vmax));
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

  // .login-canvas-text2{
  //   top: calc((1em - 1vmin) + (1em + 3vmax));
  // }

  .card__login{
    display: flex;
    flex-direction: column;
  }

  .text-left{
    flex: 1;
  }

  .login__right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login__form{
    padding-top: 0;
    margin-top: -40px;
  }

  .login-canvas-wrapperLogo2{
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .login-canvas-logo1{
    height: 40px;
    width: 65px;
  }

  .login-canvas-text1{
    top: 1.59rem;
    left: 7.15rem;
    font-size: 3.6rem;
  }

  .login-canvas-text2{
    font: 400 2.2rem/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  }
}

