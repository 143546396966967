.CustomerLst{

    overflow: auto;

    .setting{
        min-width:  70px;

    }


    .provider{
        min-width: 40px;

    }

    .enrollment-id{
        min-width: 150px;

    }

    .enrollment-name{
        min-width: 200px;
 
     }
     
    .billScopeCusts{
        min-width: 110px;

    }
/*




/*
  
    */
 

    .lastest-markup{
        min-width: 100px;
    }

  .lastest-fee{
        min-width: 120px;

    }

    .mfapproveStatus{
        min-width: 120px;
    }


    .amount-bill-scope{
        min-width: 100px;

    }
  .amount-usage-view{
        min-width: 120px;

    }


    .salesMan{
        min-width: 180px;

    }

    .start-date ,.end-date  {
        min-width: 120px;

    }

    .status{
        min-width: 100px;

    }



    
  


    tbody{
    .provider, .amount-bill-scope , .amount-usage-view  ,  .mfapproveStatus, 
      .start-date ,.end-date ,  .status  , .billScopeCusts{
        text-align: center;           
    }

    .lastest-markup , .lastest-fee{ 
        text-align: right;   
    }

  
    

}
    
  }