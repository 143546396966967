.ugh_list {

    input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .react-datepicker__input-container input:first-child{
        max-width: 110px;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
    }

    .add-color{
        color:rgb(0, 177, 0);
    }

    .add-color{
        color:green;
    }

    .del-color{
        color:red;
    }

    .del-color:hover{
        color:rgb(199, 0, 0);
    }

    .ugh__react-numeric{
        border-radius: 4px;
        border: solid 1px #c9d0d0;
        width: 100%;
        padding: 5px;
        font-size: 17px;
        color: #353535;
    }
}




.ugh__container {
    overflow: auto;
    width: 100%;
    position: relative;
    max-width: 97%;
    margin-left: 1rem;
    margin-right: 1rem;
}
.ugh__table {
    position: relative;
    overflow: auto;
    thead.thead-light { 
        tr { 
            th, button  { 
                
                color : #98a6af;
                font-weight: 600;
                /*text-transform: uppercase;*/
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    td {
        white-space: nowrap;
        vertical-align: middle;
    }
    th {
        vertical-align: middle;
    }
    .loading__container {
        position: absolute;
        width: 99.9%;
        height: 100%;
    }
}

// .ugh_table {
//     border-collapse: collapse;
//     width: 100%;
// }
// .ugh__table th:first-child, .ugh__table td:first-child {
//     min-width: 90px;
//     position: sticky;
//     left: 0px;
//     z-index: 1;
// }
.ugh_fix_firstcolumnheader {
    min-width: 90px;
    position: sticky;
    left: 0px;
}
.ugh_fix_firstcolumn {
    min-width: 90px;
    position: sticky;
    left: 0px;
    z-index: 1;
    
}
.ugh_fix_secondcolumnheader {
    min-width: 90px;
    position: sticky;
    left: 90px;
}
.ugh_fix_secondcolumn {
    min-width: 90px;
    position: sticky;
    left: 90px;
    z-index: 1;
    background-color:#EEE;
}

.ugh_fix_header { 
    position: sticky;
    top: -1px;
    z-index: 1;
}

.ugh__table_loading { 
    th:last-child,
    td:last-child {
        position: initial;
    }
}

// .xugh__table.fix__lastcolumn { 
 
//     // Fix Column
//     border-collapse: collapse;
//     width: 100%;
//     td,
//     th {
//         // border: 1px solid #dddddd;
//         text-align: left;
//         padding: 8px;
//     }
//     th:last-child,
//     td:last-child {
//        // border-left: 2px solid #ccc;
//         min-width: 140px;
//         position: sticky;
//         right: 0;
//         background: white;
//     }
// }
// .ugh__table.fix__firstcolumn { 
 
//     // Fix Column
//     border-collapse: collapse;
//     width: 100%;
//     td,
//     th {
//         // border: 1px solid #dddddd;
//         text-align: left;
//         padding: 8px;
//     }
 
    
//     th:first-child,
//     td:first-child{
//             min-width: 90px;
//         position: sticky;
//         left: 0px;
//         z-index: 1;
//         //background: white;
//     }

   

// }

// @mixin ugh__fix__secondcolumn($firstcolumnWidth) { 
 
//     // Fix Column
//  border-collapse: collapse;
//    width: 100%;
//     td,
//     th {
//         text-align: left;
//         padding: 8px;
//     }
    
//     th:nth-child(2),
//     td:nth-child(2) {
//         border-left: 2px solid #ccc;
//         min-width: 90px;
//         position: -webkit-sticky; /* for Safari */
//         position: sticky;
//         left:  $firstcolumnWidth;
//         z-index:2  ;
//         background: white;
//     }

   

// }


// .ugh__table.fix__secondcolumn_90 { 
//     $firstcolumnWidth : 90px;
//     @include ugh__fix__secondcolumn($firstcolumnWidth) 
  
//   }
