.overrideDiscountDetail{
    //TableTool
    .tableTool{
        background-color: #f4f6f9;
        border-radius: 0px;
    }

    .tableTool:hover{
        background-color: #f4f6f9;
        border-radius: 0px;
    }

    .tableTool-add{
        color:rgb(0, 177, 0);
    }

    .tableTool-add:hover{
        color:green;
    }

    .tableTool-del{
        color:red;
    }

    .tableTool-del:hover{
        color:rgb(199, 0, 0);
    }

    .tableTool-search{
        color:rgb(15, 141, 199);
    }

    .tableTool-search:hover{
        color:rgb(14, 79, 175);
    }

    //Table
    .ReactTable{
        .rt-table{
            .rt-thead{
                .rt-tr{
                    .rt-th {
                        // margin-top: 5px;
                        // margin-bottom: 5px;
                    }
                }
            }
        }
    }



    .ReactTable{
        .rt-table{
            .rt-thead{
                .rt-tr{
                    .rt-th :first-child {
                        width:100% !important;
                    }
                }
            }
        }
    }


    .fix__firstcolumn { 
 
        
   
       
        min-width: 90px;
        position: sticky;
        left: 0px;
        z-index: 1;
        background: white;
    
       
    
    }
    
    
    
    
    
    
    @mixin fix__secondcolumn($firstcolumnWidth) { 

           min-width: 90px;
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left:  $firstcolumnWidth;
            z-index:2  ;
            background: white;
       
    
       
    
    }
    
    
   .fix__secondcolumn_90 { 
        $firstcolumnWidth : 90px;
        @include fix__secondcolumn($firstcolumnWidth) 
      
      }
    




    // .ReactTable{
    //     .rt-table{
    //         .rt-tbody{
    //             .rt-tr-group :hover{
    //                 background-color: #f9fafa;
    //                 cursor: pointer;
    //             }
    //         }
    //     }
    // }
}



.custom-checkbox  {
    .custom-control-label { 
        margin-top: -3px;
    }
}