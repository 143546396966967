.d3_pickerselectitem {
    display: block;
    width: 100%;
    border: none;
    background-color: white;
    color: #2173d1;    
    padding: 7px 14px;
    font-size: 12px;
    cursor: pointer;
    text-align: left;

    .notChoose{
        color :#e76464;
     }
   
}

.d3_searching {
    color: gray;
    padding:5px 10px;
    border:1px lightgray solid;
    background-color:white;
    width:  fit-content; 
    min-width: 200;
   /* width:  100%;   */
}

.d3_pickerselectitem:hover {
    background-color: #2173d1;
    color: white  ;  
}








.d3_pickerselecteditem {

  

    background-color: #2173d1;
    color: white;
    border: none;
    padding:5px 10px;
    font-size: 12px;
    text-align: left;
    /* margin: 2px 2px 2px 2px; */
    border-radius: 3px;
    justify-content: space-between;



    min-height: calc(1.7rem + 2px   );
}

.d3_pickerselectitem.customCell{
    border-bottom: 1px lightgray solid;
}


.d3_pickerselectitem .emphasize{
    color:  #b37300;
}

.d3_pickerselectitem:hover .emphasize {
    color: unset  ; 
}





.d3_pickerselectedcontainer {
    display:flex;
    flex-wrap: wrap;
}

.d3_pickerinputpanel {
    /* border:1px lightgray solid;
    border-radius: 5px;
    background-color:white; */
}

.d3_pickertextsearch {

  /* max-width: 200px; */
    /* border:none; */
    /* margin-top: 1px;
    margin-bottom: 1px; */
    /* min-width: 250px; */
    min-width: 100px;
}
.d3_pickertextsearch.is-invalid {
    padding-right: 0rem;    
    background-image: none;
    
}



.d3_pickerinputpanel-error {
    border:1px red solid;
    border-radius: 5px;
    background-color:white;
}
.d3_pickerinputpanel.selected   {    
    border:none;
    
}



.d3_picker_container  {
/* 
 display: flex;
flex-direction: column;  */
 
/* position: absolute; */



}

.d3_searching{

     position:absolute; 
    z-index:40; 
}



.d3_pickercontainer_select{
    min-width: 100px;

    position:absolute; 
    z-index:40; 
}

.d3_pickerEndTag { 

  
    border: 1px  solid lightgray; 
    border-top: none; 
     position: absolute;
     min-width: 150px;




}



.d3_pickerselectlist { 
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
    /* z-index:40; */
    border:1px solid lightgray; 
    /* border:1px solid lightgray;  */

    /* position:absolute;  */
    min-width: 200px;
}





  .d3_pickerEndTag .d3_pickerselectitem{
 cursor: default;
/*  
    padding-top: 10px;
    background-color:  #1a88ff;
    color : white;
   */

} 




.d3_pickerEndTag .d3_pickerselectitem:hover{
      background-color: white;
  
  

} 






/**/
.d3_autocmpselectitem {
    display: block;
    width: 100%;
    border: none;
    background-color: white;
 
    color: #2173d1;
    padding: 7px 14px;
    font-size: 12px;
    cursor: pointer;
    text-align: left;
}

.d3_autocmpselectitem:hover {
    background-color: #2173d1;
    color: white;
}

.d3_autocmpselectlist { 
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: white;
    z-index:40;
    border:1px solid lightgray;
    position:absolute;
    min-width: 200px;
}