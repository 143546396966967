// .nav {
//   display: block !important; 

// }

// .navbar{
// display: none;

// }

.div-transparent::after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3) !important;
  // background-color: transparent !important;
  z-index: 99999999;
}



.partnerNav{
  .dropdown-menu{
    font-size: 0.9rem;
  }


}
.txtBold {
  font-weight: bold;
}

.txtSize-2{
  font-size: .9rem;

}


.alert-success {

  color: #3c763d !important;  
  background-color: #dff0d8 !important;  
  border-color: #d6e9c6 !important;  

}


.alert-secondary {
    color: #41464b !important;  
    background-color: #e2e3e5 !important;  
    border-color: #d3d6d8 !important;  
}
.alert a {
color: #007bff !important;  
    text-decoration: none !important;  
}





.alert-info {
  color: #31708f !important;  
  background-color: #d9edf7 !important;  
  border-color: #bce8f1 !important;  
}

.alert-warning {
  color: #856404 !important;  
  background-color: #fff3cd !important; 
  border-color: #ffeeba I !important; 
}


.alert-danger {
  color: #a94442 !important;  
  background-color: #f2dede !important;  
  border-color: #ebccd1 !important;  
}











.loading-piece {
  position: relative;
}



.loading-piece:after {
  content: "";
  position: absolute;
  width: 45px;
  height: 45px;
  border: 5px solid #c8e5ff;
  border-radius: 50%;
  border-top: 5px solid #0072ff;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}




.custom-radio {
  //padding-left: unset;

  .custom-control-label {
    display: flex;
    align-items: center;
  }

  .custom-control-label::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin-right: 0.5rem;
    min-width: 1rem;
    min-height: 1rem;
  }

  .custom-control-label::after {
    width: unset;
    height: unset;
  }

  .custom-control-label::before, .custom-control-label::after {
    position: unset;
    // top:0.1rem;
  }
}




.modal-dialog {
    .modal-content {
      .modal-header {
        color: #fff;
        font-size: 1.15rem;
        background: linear-gradient(45deg, #4099ff, #73b4ff);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    
      .modal-body {
        .alertModalBodyDetail { // UtAlert Modal Edit Gem
          text-align: left;
          padding: 5px;
          font-size: 1.1rem;
          font-weight: bold;
          margin-top: 15px;
        //   margin-bottom: 0.1rem;
          word-break: break-word;
        }
      }
    }
  }