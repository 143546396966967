

.noticeBox{

  padding: 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #005294;
  
  .header{

    font-size: 1.5rem;

  }
  .detail{
    div{

      margin-top: 2rem;
    }


  }



}


.RedirectSite{

    background-color: #cce5ff;
    color:white;

    height: 100vh;


    // width: 100vh;
    // height: 100vh;
    //  display: flex;
    //  flex-direction: column;
   // align-content: center;


    a {
        color: #66b0ff;
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
      }
      
      a:hover {
        color: #4da3ff;
        text-decoration: none;
      }

 
  

    .leftBox{
        background-color: #005294;
        width: 35%;  
        height: 100vh;
    }

    .box{

      //  width: 100%;  
        padding: 3rem;
    }
    .txtHead{
        font-size: 1.6rem;
        font-weight: bold;

    }

    .txtSmall{
        font-size: 1rem;


    }





    font-size: 1.2rem;



.logoRow{

    display: flex;


}
.logoM{
//   position: absolute;
//   top: 3rem;
//   left: 3rem;

  height: 30px;
  width: 50px;
  margin-right: 3px;

}



}

