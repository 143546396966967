.csp__container {
    overflow: auto;
    width: 100%;
    position: relative;
    max-width: 97%;
    margin-left: 1rem;
    margin-right: 1rem;
}
.csp__table {
    position: relative;
    overflow: auto;
    thead.thead-light { 
        tr { 
            th, button  { 
                
                color : #98a6af;
                font-weight: 600;
                text-transform: uppercase;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    td {
        white-space: nowrap;
        vertical-align: middle;
    }
    th {
        vertical-align: middle;
    }
    .loading__container {
        position: absolute;
        width: 99.9%;
        height: 100%;
    }
}

.csp__table.fix__lastcolumn { 
 
    // Fix Column
    border-collapse: collapse;
    width: 100%;
    td,
    th {
        // border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
    th:last-child,
    td:last-child {
       // border-left: 2px solid #ccc;
        min-width: 140px;
        position: sticky;
        right: 0;
        background: white;
    }
}
.csp__table.fix__firstcolumn { 
 
    // Fix Column
    border-collapse: collapse;
    width: 100%;
    td,
    th {
        // border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
 
    
    th:first-child,
    td:first-child{
            min-width: 90px;
        position: sticky;
        left: 0px;
        z-index: 1;
        background: white;
    }

   

}






@mixin fix__secondcolumn($firstcolumnWidth) { 
 
    // Fix Column
 border-collapse: collapse;
   width: 100%;
    td,
    th {
        text-align: left;
        padding: 8px;
    }
    
    th:nth-child(2),
    td:nth-child(2) {
        border-left: 2px solid #ccc;
        min-width: 90px;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        left:  $firstcolumnWidth;
        z-index:2  ;
        background: white;
    }

   

}


.csp__table.fix__secondcolumn_90 { 
    $firstcolumnWidth : 90px;
    @include fix__secondcolumn($firstcolumnWidth) 
  
  }


.fix__header thead th{ 
    position: sticky;
    top: -1px;
    z-index: 1;
}

.csp__table_loading { 
    th:last-child,
    td:last-child {
        position: initial;
    }
}

.search__panel {
    background-color: rgb(244, 244, 244);
    margin: 1em;
    border: 1px solid #dee2e6;
}

.search__input {
    .input-group-prepend {
        display: flex;
        align-items: center;
        background: white;
        border: 1px solid #ced4da;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        padding-left: 10px;
        color: #8b9299;
    }
    input {
        border-left: 0;
    }
}

.search__select {
    color: #8b9299;
}
