.tableTicketList{
     


 

  



    tbody{

      border: 0px !important ; 
   }

    thead th {
      // border-top :1px  solid #dee2e6; ;
      //  border-bottom :1px  solid #dee2e6  ;

       border-top :1px  solid   #BFBFBF ;
       border-bottom :1px  solid   #BFBFBF  ;

     

    }



     td{

        border-top: 0px ;
     }


     td.mainRow{

      //  border-top: 1px solid #dee2e6 ;
        padding-bottom: 0em  ;
     }

     td.subRow{

        padding-top: 0px  ;
     }



     th{
        font-size: 1.4em; 
     }

 
    th.rightcell , td.rightcell{
        text-align: right  ;
    }

   



    th.product{
       width:20%;
    }


    th.other{
        width:30%; ;
    }



    tbody.canClick{
        cursor: pointer; 
    }

    tbody.canClick:hover {
      background-color: #ECF0F1;
  }






    .bigContent{
        font-size: 1.5em; 
    }

   .middleContent{
      font-size: 1.2em; 
    }

   
}


.sidebar{

 

   .header{
      
        padding: 0px ;
      }
   .menu{
      cursor: pointer; 
      font-size: 1.1em; 
      padding : 0 px; 
    //   padding: 10px 5px 10px 5px;
    }


    $badgeWidth: 25px;


    .badge{
      font-size: 1em; 

     width :  $badgeWidth;
     padding-top : 5px; 
    padding-bottom : 5px; 
    }

    
     

   
}


