.downloadazure{
    .editBtn{
        // font-size: 10px;
        font-size: 90%;
        align-items: center;
        justify-content: center;
        // margin-top: 9px;
    }
    
    .delBtn{
        color: red;
    }
    
    .rect_tb_fix__firstcolumn { 
        .editToolCol{
            position: sticky !important;
            z-index:1;
            left: 0px;
            // min-width: 190px;
            // background: red;
        }
    }

    //Modify ReactTable
    .ReactTable {
        border: 0px;
    }

    .ReactTable .rt-thead.-header {
        box-shadow: 0 0px 0px 0 rgba(0,0,0,0);
    }
}