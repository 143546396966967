.memo{
    $headerTextColor: white;
    border-radius: 4px;
    box-shadow: 4px 4px 2px 0px #858585;


    .toolBar{
        height:75px;

        font-size:1.4em;
        color: #ffffff;
        .custom-control-inline {
            margin-left:10px;
            margin-right: -2px;
            .custom-control-label{
                margin-top: 6px;
            }
       
        }


        .typingBoxCol {

            flex-grow: 100;

        
        .form-control{

            font-size:0.9em;
        }
         textarea{

            resize: none;
            height:80px;
        }


    }
       

        



        .sendBtnCol{

            vertical-align: middle;
            .btn{
                font-size:1.9em;               
              

                color: #ffffff;    
                margin-right: 10px;
               
                .fas{
                 vertical-align: super  ;

                }

            }


            .btn:hover{
                color: #007BFF;    

            }


             
    
        }


     

    }

  


    
    

    // .toolBar .typingBox{

    //    // width:100%;


    // }


    // .toolBar .custom-control-input{
    //        // margin-right: 20px;

    //         display:none;
    
    //  }


     
   


    .frame{
        background-color: #00447b;
    }

    .memoIcon{
        color: $headerTextColor;
    }

    .memoHeader{
        color: $headerTextColor;
        font-size: 1.35rem;
        font-weight: bold;
    }
    
    .memoBody{
         min-height: 300px;
         max-height: 500px;       
        overflow: scroll;
        background-color: #f7f7f7;

  
    }

    

    .memoBody.nomessageContainer{
        font-size:1.6rem;
        display : flex;
        align-items : center;
        justify-content: center;

    }




    .memoAvatar{
        font-size: 2rem;
        margin-top: 8px ;
    }

    .headerCard{
        font-size: 0.8rem;
        margin-bottom:2px ;
    }
    .timeCard{
        font-size: 0.6rem;
     
    }


    .messageCol
    {
        // left: calc((.5rem + 1px) * -1);
        // width: 70%;
        // height: 1rem;
        // margin: .3rem 0;
        // min-width: 200px;
        max-width: 60%;

        // width: %;
    }

    .message
    {
        // left: calc((.5rem + 1px) * -1);
        // width: 70%;
        // height: 1rem;
        // margin: .3rem 0;
        // min-width: 200px;
        // max-width: 74%;

       // width: 100%;
       font-size: 0.8rem;
       white-space: pre-line;
        margin-bottom: 0px  !important ; 
          

    }

    .message.opener
    {
   background: #044F67;
   color: #ffffff;    

    }

    .memoAvatar.opener{
        color: #264348 ;
    }



}


