.azure_usage_detail{
    .tableAzure tbody td{
        padding: 0.2rem;
    }

    .tableAzure th{
        color: #003661;
        //font-weight: normal
        background-color:#f4f4f4;
        //text-align: right;
    }

    .tableAzure tbody tr{
        border-bottom: hidden;
        //background-color: red !important;
    }

    .tableAzure tbody {
        display:block;
        max-height:300px;
        overflow:auto;
        //border-bottom: hidden;
    }

    .tableAzure thead, tbody tr {
        display:table;
        width:100%;
        table-layout:fixed;/* even columns width , fix width of table too*/
    }

    .tableAzure tbody td {
        //border:0;
    }

 
    .tableAzure thead {
        width: 100%;/* scrollbar is average 1em/16px width, remove it from thead width */
    }

    .tableAzure {
        font-size: 0.6rem;
        width:100%;
    }


    // .tableAzure tbody tr:last-child{
       
    //     background-color:#f4f4f4 !important;
    // }

    .tableAzure tbody tr:last-child{
        border-top: 2px solid #dee2e6 !important;
        border-bottom: 2px solid #dee2e6 !important;
    }

    .color_gray{
        color: #999999;
        font-weight: normal
    }

    .display-border-bottom{
        border-bottom: 2px solid #dee2e6 !important
    }

    .display-border{
        border-top: 2px solid #dee2e6 !important;
        border-bottom: 2px solid #dee2e6 !important;
    }

    .bg-header-marketplace{
        background-color: #B251ED;
        color: white;
    }

    //Add by mail

    .tableVerticalWidth{
        width: fit-content
    }
}