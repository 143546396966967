
@import "../../css/global.scss";  

.icon__container { 
    max-height: 70vh;
    overflow: auto;
    padding-top: 2em;
    list-style-type: none;
}
.icon__list-select { 
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    align-items: start;
    justify-content: flex-start;
    display: flex;
}
.icon__label { 
    border-radius: 8px;
    transition: box-shadow 0.2s linear; /* vendorless fallback */
    -o-transition: box-shadow 0.2s linear; /* opera */
    -ms-transition: box-shadow 0.2s linear; /* IE 10 */
    -moz-transition: box-shadow 0.2s linear; /* Firefox */
    -webkit-transition: box-shadow 0.2s linear; /*safari and chrome */

}
.icon__name-container { 
    min-height: 30px;
}
.icon__panel { 
    min-height: 55px;
    padding-top: .5rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // &:hover { 
    //     color: white;
    //     box-shadow: 1px 2px 3px rgba(0,0,0,.1);
    // }

}
.icon__select { 
    display: inline-block;
    min-width: 100px;
    padding: .5rem;
    padding-bottom: 1rem;
    text-align: center;

    i {
        font-size: 38px;
    }
    &:hover { 

        .icon__panel{ 
            border: 2px solid $primary-color;
            cursor: pointer;
        }
        .icon__label { 
            box-shadow: 1px 2px 3px rgba(0,0,0,.1);
            cursor: pointer;
            
        }
        .icon__name{ 
            color: $primary-color;
            cursor: pointer;
        }
        color: $primary-color;
        cursor: pointer;
    }
    &.selected{ 
        .icon__panel{ 
            background: $primary-color;
            cursor: pointer;
        }
        .icon__label { 
            box-shadow: 1px 2px 3px rgba(0,0,0,.1);
            cursor: pointer;
            
        }
        .icon__name{ 
            color: $primary-color;
            cursor: pointer;
        }
        color: white;
        cursor: pointer;
    }
}

.icon__name {
    color: #ccc;
    font-size: 12px;
}

@media screen and (min-width: 77.5rem){

    .icon__select {
        width: calc(100%/9);
    }
}

@media screen and (min-width: 60em){
    .icon__select {
        width: calc(100%/6);
    }
}
