

.useraccount_detail_page { 
 
        

    .form-group {
        margin-bottom: 0.5rem;
    }


   

}




