$darkGray : #373f44;

.refPicker{

 display : flex;
 align-items: baseline;

 .txtHeader{

   // font-size: medium;
   // font-size: 1rem;   
    font-weight: bold;
    color:$darkGray;
    white-space: nowrap;
}




}