.DefaultTable {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;

    //overflow-x:scroll;

    //overflow: auto;
  //  overflow-x:auto;
    //min-width: 100%;



 
    thead {
        th {
            vertical-align: bottom;
            border-bottom: 2px solid rgba(222, 226, 230,0.7);
        }
        background-color:  #fbfbfc
    }


    tbody {
        td {
            vertical-align: top;
            border-top: 1px solid rgba(222, 226, 230, 0.5);
        }
        background-color:  #fefefe       

    }
    th, td {
        padding: 0.5rem;    
    }

}