.PeriodValueSetting {

  

  .txtWarning {
    color: #b37300  ;
  }

  .diff {
    .form-control {
    color: #b37300 !important ;
    }
  }





  table {
    border: 1px solid rgba(0, 0, 0, 0.1);


    thead {
      th {
        vertical-align: bottom;
        border-bottom: 2px solid rgba(222, 226, 230, 0.7);
      }

      background-color: #e6e6e6
    }

    tbody {
      td {
        vertical-align: baseline;
        border-top: 1px solid rgba(222, 226, 230, 0.5);
       }
       background-color: #fefefe ; 


      .displayRow{    
     

        .Value {      
          padding-right: 10px;
        }        
      } 



      .displayRow.just_new_row{    
     
        
        background-color:  #f2f2f2 ;        
      } 


      



      .From,.To {
       text-align: center;
      }
      .react-datepicker__input-container{
        input{
          text-align: center;
          
        }


      }

      
  




    }    


    th,
    td {
      padding: 0.2rem;
    //  vertical-align: baseline;
    }

    td {

      //   display: none;
     // vertical-align: middle;
    }

    // .No {
    //   width: 30px;
    // }

    .Tool {
      width: 55px;
      text-align: right;
      padding-right: 10px;

      .disabled{
        opacity: 0.5;
      }

    }

    .From,.To {
      width: 120px;
    }

    .Value {
      width: 100px;
    }

    td.Value {
      text-align: right;
    }
  }
}