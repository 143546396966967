.notFound{

    font-size: 2rem;
  font-weight: bold;

  color:#cccccc;
  //width: 100%;
 // text-align: center;


}

.manualBox{


    margin:  1rem;

 

    .row{
display: flex;

    }

    .header{

        font-size: 1.2rem;
      font-weight: bold;



    }


    .colCaption{
        width:1%;
        white-space: nowrap;

    }

    tr{
        background-color: rgba(0,0,0,.05);

      

    }
}