.editBtn {
  // font-size: 10px;

  font-size: 90%;
  align-items: center;
  justify-content: center;
  // margin-top: 9px;
}

.delBtn {
  color: red;
}

.rect_tb_fix__firstcolumn {
  .editToolCol {
    position: sticky !important;
    z-index: 1;
    left: 0px;
    width: auto !important;
    // min-width: 190px;
    // background: red;
  }
}

.userListTB {
  .rt-td {
    white-space: normal !important ;
  }
}
