
.chkBox{

    label{

      //  font-size: medium;
    font-weight: bold;
    color: #373f44;
    white-space: nowrap;
    }
 
}