.usermodelpage{

    .rt-td {
       background-color:transparent ;

    }
    .modeltb {
    
    
        .canClick  .rt-td {
            cursor: pointer;
         
             }

   .custom-control-label{
   cursor: pointer;

    }

        }  

}










