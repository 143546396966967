






.permissionapply_list{
   

    
    .chkCol{
        position: sticky !important;
        z-index:1;
        left: 0px;
       
    }
    .toolCol{
        position: sticky !important;
        z-index:1;
        // left: 40px;
    }
    





    .tableTool{
       background-color: #f4f6f9;
        border-radius: 0px;
    }

    .tableTool:hover{
        background-color: #f4f6f9;
        border-radius: 0px;
    }

    .tableTool-add{
        color:rgb(0, 177, 0);
    }

    .tableTool-add:hover{
        color:green;
    }

    .tableTool-del{
        color:red;
    }

    .tableTool-del:hover{
        color:rgb(199, 0, 0);
    }
    .button-sort {
        background-color: #007bff;
        border: none;
        color: white;
        padding: 1px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 4px;
        cursor: pointer;
      }
    .button-sortClick {
        background-color: #ffc107;
        border: none;
        color: white;
        padding: 1px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 4px;
        cursor: pointer;
      }
      .colsize{
        padding-top: 2px;
        padding-bottom: 4px;
      }
   
}
